export const environment = {
  firebase: {
    apiKey: "AIzaSyCTZMFumaGiSOSZDElyvtgfu5iCJLQpvZI",
    authDomain: "creative-wiz-dev.firebaseapp.com",
    databaseURL: "https://creative-wiz-dev-default-rtdb.firebaseio.com",
    projectId: "creative-wiz-dev",
    storageBucket: "creative-wiz-dev.appspot.com",
    messagingSenderId: "779424351391",
    appId: "1:779424351391:web:20d0e5b91d0d699774ceaa",
    measurementId: "G-VCD87YRM4H"
  },
  production: true,
  serverApiUrl: 'https://translator-prod-api.creativewiz.nl/api/v1',
  storeDefaultCacheTimeInMilliSeconds: 5000,
};
